import React from 'react';
import LayoutMessage from 'layouts/layout-message';
import { SEO } from 'components/shared/seo/seo';
import Form from 'components/pages/send-message/form';

export default function() {
  return (
    <LayoutMessage>
      <SEO />
      <Form />
    </LayoutMessage>
  );
}
