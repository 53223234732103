import React from 'react';
import PropTypes from 'prop-types';
import Freshchat from 'components/shared/freshchat';
import styles from './layout-message.module.scss';

const LayoutMessage = ({ children }) => (
  <>
    <main>{children}</main>
    <Freshchat />
  </>
);

LayoutMessage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutMessage;
