import React, { useState, useEffect, useRef } from 'react';
import useLottie from 'utils/use-lottie';
import { Link } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import axios from 'axios';
import classNames from 'classnames';
import { encode } from 'utils';
import styles from './form.module.scss';
import NameIcon from './images/name.inline.svg';
import MailIcon from './images/mail.inline.svg';
import PhoneIcon from './images/phone.inline.svg';
import SubjectIcon from './images/subject.inline.svg';
import MessageIcon from './images/message.inline.svg';
import HomeIcon from './images/home.inline.svg';
import Logo from './images/logo.inline.svg';

const formValidationSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup
    .string()
    .email()
    .required(),
  phone: yup.string(),
  subject: yup.string().required(),
  message: yup.string().required(),
});

const Form = () => {
  const [animationMobileRef, inMobileView] = useInView();
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  const { register, handleSubmit, errors } = useForm({
    validationSchema: formValidationSchema,
  });

  const animationOptions = {
    renderer: 'svg',
    loop: true,
    autoplay: false,
    path: '/animations-data/claim-animation.json',
  };

  const { animation, animationRef: animationContainer } = useLottie({
    ...animationOptions,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMin slice',
    },
  });

  const {
    animation: animationMobile,
    animationRef: animationContainerMobile,
  } = useLottie({
    ...animationOptions,
  });

  useEffect(() => {
    if (inMobileView && animation) {
      animation.pause();
      animationMobile.play();
    }
    if (!inMobileView && animation) {
      animation.play();
      animationMobile.pause();
    }
  }, [inMobileView, animation, animationMobile]);

  const onSubmit = async (formData, event) => {
    setLoading(true);
    try {
      const [response] = await Promise.all([
        axios.post('/', encode(formData), {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }),
        new Promise(resolve => setTimeout(resolve, 600)),
      ]);
      setLoading(false);
      setSuccessMessage(true);
    } catch (error) {
      setLoading(false);
    }
    event.target.reset();
  };

  return (
    <>
      <div className={styles.animation} ref={animationContainer} />
      <section className={`section-default ${styles.wrapper}`}>
        <div className="container">
          <div className={`columns is-multiline ${styles.inner}`}>
            <div
              className={`column is-12 is-5-desktop ${styles.right}`}
              ref={animationMobileRef}
            >
              <div
                className={styles.mobileAnimation}
                ref={animationContainerMobile}
              />
            </div>
            <div className="column is-12 is-offset-6-desktop is-6-desktop">
              <Link to="/" className={styles.logoContainer}>
                <Logo className={styles.logo} />
              </Link>
              <div className={styles.formContainer}>
                {successMessage ? (
                  <div className="message-wrapper">
                    <HomeIcon />
                    <h2 className={`heading md ${styles.messageTitle}`}>
                      Thank you for your message!
                    </h2>
                    <p className={styles.messageText}>
                      We will contact you shortly
                    </p>
                    <a className="button primary large" href="/">
                      Back to the homepage
                    </a>
                  </div>
                ) : (
                  <>
                    <h1 className={`heading md ${styles.title}`}>
                      Send us a message
                    </h1>
                    <form
                      name="send-message"
                      data-netlify="true"
                      data-netlify-honeypot="bot-field"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className={styles.formBorder}>
                        <div className={styles.control}>
                          <div className={styles.icon}>
                            <NameIcon />
                          </div>
                          <input
                            className={styles.input}
                            type="text"
                            name="name"
                            placeholder="Name"
                            ref={register({ required: true })}
                          />
                          {errors.name && (
                            <span className={styles.errorInput}>
                              {errors.name.message}
                            </span>
                          )}
                        </div>
                        <div className={styles.control}>
                          <div className={styles.icon}>
                            <MailIcon />
                          </div>
                          <input
                            className={styles.input}
                            type="email"
                            name="email"
                            placeholder="Email"
                            ref={register({ required: true })}
                          />
                          {errors.email && (
                            <span className={styles.errorInput}>
                              {errors.email.message}
                            </span>
                          )}
                        </div>
                        <div className={styles.control}>
                          <div className={styles.icon}>
                            <PhoneIcon />
                          </div>
                          <input
                            className={styles.input}
                            type="tel"
                            name="phone"
                            placeholder="Phone number"
                            ref={register({ required: false })}
                          />
                          {errors.phone && (
                            <span className={styles.errorInput}>
                              {errors.phone.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className={styles.formBorder}>
                        <div className={styles.control}>
                          <div className={styles.icon}>
                            <SubjectIcon />
                          </div>
                          <input
                            className={styles.input}
                            type="text"
                            name="subject"
                            placeholder="Subject"
                            ref={register({ required: true })}
                          />
                          {errors.subject && (
                            <span className={styles.errorInput}>
                              {errors.subject.message}
                            </span>
                          )}
                        </div>
                        <div
                          className={`${styles.control} ${styles.controlMessage}`}
                        >
                          <div className={styles.icon}>
                            <MessageIcon />
                          </div>
                          <textarea
                            className={`${styles.input} ${styles.textarea}`}
                            name="message"
                            placeholder="Message..."
                            rows="2"
                            ref={register({ required: true })}
                          />
                          {errors.message && (
                            <span className={styles.errorInput}>
                              {errors.message.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <input
                        type="hidden"
                        name="form-name"
                        value="send-message"
                        ref={register()}
                      />
                      <button
                        className={`button primary large full ${classNames({
                          loading,
                        })}`}
                        type="submit"
                      >
                        <span>Send message</span>
                      </button>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Form;
