import Lottie from 'lottie-web';
import { useState, useRef, useEffect } from 'react';

const useLottie = options => {
  const animationRef = useRef();
  const [animation, setAnimation] = useState();

  useEffect(() => {
    const lottieAnimation = Lottie.loadAnimation({
      container: animationRef.current,
      ...options,
    });
    setAnimation(lottieAnimation);
    return () => {
      lottieAnimation.destroy();
    };
  }, []);

  return { animation, animationRef };
};

export default useLottie;
